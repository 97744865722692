import React from "react";
import Layout from "../other/layout";
import PolitiqueConf from "../components/AC-StaticPages/PolitiqueConf";
import SEO from "../other/seo";

export default function PolitiqueConfPage() {
  return (
    <Layout>
      <SEO title="Politique de confidentiialité" />

      <PolitiqueConf />
    </Layout>
  );
}
