import React from "react";
import styles from "./styles.module.css";

export default function PolitiqueConf() {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <h1>Politique de confidentiialité</h1>
        <div class="content">
          <p>
            Produits D'Affaires Deluxe limitee offre un service hébergé de
            traitement de la paie aux petits commerces d'un bout à l'autre du
            Canada. Nous respectons rigoureusement la vie privée de toutes les
            personnes qui utilisent notre site Web, nos produits et nos
            services.{" "}
          </p>{" "}
          <br />
          <p>
            Cette politique de confidentialité, en vigueur au 30 juin 2006,
            s'applique à tous les renseignements permettant d'identifier nos
            clients que nous obtenons lorsqu'ils visitent ce site Web. Notre
            engagement à respecter et protéger les renseignements personnels de
            nos clients est reconnu. Nous nous efforçons constamment de protéger
            votre vie privée et d'utiliser des technologies qui vous procurent
            le plus haut niveau de sécurité en ligne disponible. Soyez assurés
            que nous demandons des renseignements à nos clients dans le seul but
            d'améliorer nos relations avec eux.
          </p>
          <span>Principes directeurs de la confidentialité</span>
          <p>
            Le type de renseignement que nous recueillons à votre sujet dépend
            entièrement de l'usage que vous choisissez de faire de nos services.
            Nous ne demandons que les renseignements nécessaires pour vous
            donner le meilleur service possible. Exemples :
          </p>
          <br />
          <ul>
            <li>
              Exactitude : Nous nous engageons à prendre les mesures nécessaires
              pour que les renseignements recueillis, utilisés et échangés
              soient exacts et complets.
            </li>
            <li>
              Sécurité : Nous nous engageons à prendre les mesures de sécurité
              nécessaire pour protéger les renseignements contre l'accès,
              l'utilisation et la divulgation non autorisés.
            </li>
            <li>
              Respect de la loi : Nous nous engageons à recueillir, utiliser et
              échanger les données en conformité avec les lois en vigueur et
              avec les obligations contractuelles.
            </li>
            <li>
              Confidentialité : Nous nous engageons à recueillir, utiliser et
              échanger des données en conformité avec les normes de
              confidentialité appropriées.
            </li>
            <li>
              Respect de la vie privée : Nous nous engageons à
              l'autoréglementation en regard des attentes quant au respect de la
              vie privée, conformément à notre politique et aux normes les plus
              strictes de l'industrie.
            </li>
            <li>
              Réceptivité : Nous nous engageons à répondre rapidement et
              adéquatement à toute requête concernant la protection des
              renseignements personnels.
            </li>
            <li>
              Formation du personnel : Nous nous engageons à éduquer nos
              employés sur les pratiques régissant les renseignements et sur la
              manipulation adéquate et le bon usage des renseignements.
            </li>
            <li>
              Autovérification : Nous nous engageons à évaluer périodiquement
              nos activités internes pour nous assurer qu'elles respectent les
              pratiques, politiques et procédures établies concernant les
              renseignements.
            </li>
          </ul>
          <br />
          <p>
            Les énoncés suivants vous aideront à comprendre comment nous
            recueillons, utilisons et préservons les renseignements que vous
            nous fournissez par le biais de notre site Web.
          </p>
          <span>Renseignements personnels</span>
          <p>
            <em>Chez Produits D'Affaires Deluxe limitee</em>, nous avons
            l'intention de vous donner le maximum de contrôle sur vos
            renseignements personnels. En général, vous pouvez visiter notre
            site Web sans nous dire qui vous êtes ni divulguer le moindre
            renseignement à votre sujet. Il arrive parfois cependant que nous
            ayons besoin de renseignements à votre sujet tel que décrit
            ci-dessous. Si vous nous dites que vous ne voulez pas que ces
            renseignements servent à entrer en contact avec vous, nous
            respecterons votre souhait. Notez que nous retraçons les domaines à
            partir desquels les gens nous visitent. Nous analysons ces données à
            des fins statistiques et d'étude de tendances, puis nous les
            supprimons.{" "}
          </p>
          <br />
          <p>
            Notre engagement envers le respect de votre vie privée est
            indéfectible. Cet engagement couvre les renseignements que nous
            recueillons à votre sujet et l'usage que nous en faisons. Nous avons
            également un énoncé de sécurité qui décrit comment nous protégeons
            les renseignements délicats.{" "}
          </p>
          <br />
          <span>
            Renseignements à votre sujet recueillis sur notre site Web
          </span>
          <p>
            Le type de renseignements que nous recueillons à votre sujet dépend
            entièrement de la façon dont vous utilisez nos services. Nous ne
            demandons que les renseignements qui nous aideront à mieux vous
            servir. Par exemple, lorsque vous faites des achats, votre nom,
            adresse, numéro de téléphone ou adresse électronique et
            l'information de carte de crédit sont requis pour effectuer l'achat
            de façon efficace, pour une livraison exacte et pour communiquer
            avec vous si nécessaire.{" "}
          </p>
          <br />
          <p>
            Pour les demandes de catalogues ou d'échantillons de produits, nous
            avons besoin d'un nom et d'une adresse pour acheminer le colis, en
            plus du nom de la personne à contacter et son numéro de téléphone ou
            son adresse électronique au cas où nous devrions vérifier l'adresse
            ou la mettre à jour. Pour les communications par courriel, il ne
            nous faut que votre adresse électronique.{" "}
          </p>
          <br />
          <p>
            De temps à autre, il nous arrive d'effectuer des sondages
            facultatifs ou tenir des concours. Les formulaires de sondage ou de
            participation peuvent demander de fournir des renseignements de type
            démographique ou portant sur la personne à contacter ou sur les
            préférences d'achat. Si vous désirez vous inscrire à notre service
            de traitement de la paie, le formulaire d'inscription n'exige que
            les renseignements d'achat. Les autres questions portent sur le
            secteur d'activité et le logiciel de comptabilité utilisé, et vous
            êtes libre d'y répondre ou non.{" "}
          </p>
          <span>Relations d'affaires </span>
          <p>
            Le site Web de Produits D'Affaires Deluxe limitee comporte des liens
            vers d'autres sites Web. Produits D'Affaires Deluxe limitee n'assume
            aucune responsabilité quant aux pratiques de confidentialité ou au
            contenu de ces sites.{" "}
          </p>
          <span>Sondages et concours </span>
          <p>
            Occasionnellement, Produits D'Affaires Deluxe limitee peut effectuer
            un sondage ou tenir un concours. Vous êtes libre d'y participer ou
            non. Nous employons les renseignements recueillis par ces méthodes
            pour mettre au point de nouveaux produits améliorés et vous les
            proposer. De plus, ces renseignements peuvent nous aider à vous
            offrir une visite adaptée de notre site, en vous montrant des
            produits et services susceptibles d'être utiles à votre entreprise.{" "}
          </p>
          <span>Accès à vos renseignements personnels </span>
          <p>
            à titre de client estimé de Produits D'Affaires Deluxe limitee, vous
            avez le droit de consulter vos renseignements personnels et de les
            faire corriger en cas d'inexactitude. Il vous revient entièrement de
            décider si vous voulez nous fournir des renseignements personnels en
            tout ou en partie, ou de nous interdire d'utiliser ou divulguer vos
            renseignements à certaines fins. Le fait de vous prévaloir de ce
            droit pourrait cependant nous empêcher de livrer les produits et
            services demandés.{" "}
          </p>
          <span>Application </span>
          <p>
            Périodiquement, nos opérations et pratiques commerciales sont
            examinées pour en vérifier la conformité à la politique de
            l'entreprise et aux procédures régissant la confidentialité des
            renseignements. Ces examens sont faits par des vérificateurs
            internes qui font rapport directement au conseil d'administration de
            notre société mère, à des cabinets externes de vérification et de
            comptabilité, et à des responsables de la réglementation du
            gouvernement. Ces auto-évaluations examinent les mesures de contrôle
            et de protection concernant les renseignements personnels des
            consommateurs et qui sont décrites dans notre politique de
            confidentialité.{" "}
          </p>
          <span>Votre acceptation de ces modalités </span>
          <p>
            En utilisant ce site Web, vous consentez aux modalités de la
            politique de confidentialité qui y est affichée. Si vous n'êtes pas
            d'accord avec cette politique de confidentialité, veuillez ne pas
            utiliser ce site Web. Nous nous réservons le droit de remplacer,
            modifier, ajouter ou enlever des parties de cette politique à tout
            moment, et ce, à notre entière discrétion. Votre usage continu de ce
            site Web après que des modifications aux modalités de notre
            politique de confidentialité y aient été affichées signifie que vous
            acceptez ces modifications.{" "}
          </p>
          <span>Modifications à la politique de confidentialité </span>
          <p>
            Nous pouvons modifier notre politique de confidentialité à tout
            moment. Chaque changement à la politique de confidentialité et de
            sécurité sera affiché sur ce site Web pour que vous soyez informé
            des renseignements que nous recueillons et de l'usage que nous en
            faisons, et des dernières nouvelles sur les moyens que nous prenons
            pour assurer la sécurité de notre site Web.{" "}
          </p>
          <span>
            Comment déposer une plainte concernant la protection des
            renseignements personnels{" "}
          </span>
          <p>
            Vous pouvez déposer une plainte concernant la protection des
            renseignements personnels en communiquant avec l'agent principal à
            la protection des renseignements de Produits D'Affaires Deluxe
            limitee à l'aide d'une des méthodes suivantes :{" "}
          </p>
          <br />
          <p>
            Par téléphone au 1 800 465-2888, poste 2700. <br />
            Par télécopieur au 1 800 461-3325, à l'attention de l'agent
            principal à la protection des renseignements <br />
            Par courriel : canadaprivacyofficer@nebs.com <br />
            Par la poste :{" "}
          </p>{" "}
          <br />
          <blockquote>
            Agent principal à la protection des renseignements <br />
            Produits d'Affaires Deluxe limitée <br />
            330 Cranston Crescent, <br />
            Midland, Ontario L4R 4V9
          </blockquote>
          <p> </p>
          <span>Vos options quant à nos communications </span>
          <p>
            Sur le formulaire d'adhésion, vous pouvez choisir de recevoir
            diverses communications de notre part. Si, à tout moment, vous
            désirez cesser de recevoir ces communications (courriels, appels
            téléphoniques, télécopies, bulletins d'information ou publicité de
            la part de nos partenaires), vous pouvez nous le faire savoir sur le
            formulaire d'adhésion. Vous pouvez également choisir de ne plus
            recevoir ces communications de même que des catalogues et
            prospectus, à l'aide d'une des méthodes suivantes :{" "}
          </p>
          <br />
          <p>
            {" "}
            Par téléphone au 1 800 465-2888, poste 2384. <br />
            Par télécopieur au 1 800 461-3325. <br />
            Par courriel : listmgmt@nebs.com <br />
            Par la poste :{" "}
          </p>
          <br />
          <blockquote>
            Service de gestion des listes <br />
            Produits d'Affaires Deluxe limitée <br />
            330 Cranston Crescent, <br />
            Midland, Ontario <br />
            L4R 4V9
          </blockquote>
          <p> </p>
          <p>
            Finalement, dans le cadre de notre exploitation, nous échangeons de
            temps à autre le nom et l'adresse de votre entreprise avec nos
            partenaires. Si vous ne voulez pas que le nom et l'adresse de votre
            compagnie soient échangés avec nos partenaires, communiquez avec
            nous à l'aide d'une des méthodes ci-dessus.{" "}
          </p>
          <h2>DÉCLARATION DE SÉCURITÉ</h2>
          <span>Protection de vos transactions</span>
          <p>
            Chez Produits D'Affaires Deluxe limitee, nous utilisons une
            technologie de pointe pour sécuriser toutes les transactions. Voici
            quelques-unes des mesures que nous prenons pour vous protéger et
            protéger les données que vous nous remettez.{" "}
          </p>
          <span>La technologie SSL</span>
          <p>
            Nous employons la technologie appelée " protocole sécurisé de
            cryptage " qui chiffre toute donnée transmise entre vous et Produits
            D'Affaires Deluxe limitee, pourvu que votre navigateur Web puisse
            prendre la technologie SSL. Les versions récentes de tous les
            principaux navigateurs Web peuvent prendre cette technologie. Les
            données chiffrées sont infiniment plus difficiles à décoder que les
            données ordinaires, ce qui les rend beaucoup moins attirantes pour
            un pirate informatique. Avant même que vous n'envoyiez vos
            renseignements à Produits D'Affaires Deluxe limitee, votre
            navigateur les aura chiffrés. Ils demeureront chiffrés durant toute
            la transmission pour être ensuite décodés par notre serveur Web une
            fois reçus. Pour pouvoir utiliser la technologie SSL, nous avons dû
            solliciter le privilège de faire du commerce électronique et nous
            soumettre à un rigoureux processus d'approbation afin d'obtenir un
            certificat numérique. Notre certificat numérique a été délivré par
            un service de sécurité autorisé après que nous ayons fait la preuve
            que nous sommes une entreprise honnête. Ce certificat numérique a
            ensuite été installé sur notre serveur Web pour que nous puissions
            dorénavant vous faire bénéficier de la sécurité qu'offre la
            technologie SSL.{" "}
          </p>
          <span>Autres mesures de sécurité sur Internet </span>
          <p>
            Voici quelques-uns des autres moyens qui vous permettent de savoir
            si la transaction que vous êtes en train d'effectuer est sûre :{" "}
          </p>
          <br />
          <ul>
            <li>
              La plupart des sites Web de bonne réputation affichent leurs
              mesures de sécurité bien en évidence.{" "}
            </li>
            <li>
              Une adresse URL sûre commence par https://, tandis qu'une adresse
              URL n'offrant pas de transactions WEB sûres commence par http://.{" "}
            </li>
            <li>
              Beaucoup de navigateurs comportent des indicateurs visuels vous
              informant qu'une transaction protégée par SSL est en cours.
              Recherchez une icône montrant une serrure ou une clef entières
              (une serrure ouverte ou une clef brisée indiquent un environnement
              non sécurisé).{" "}
            </li>
            <li>
              Certains navigateurs utilisent une boîte de dialogue pour vous
              signifier que vous avez accédé à un serveur Web sûr.{" "}
            </li>
          </ul>
        </div>{" "}
      </div>
    </div>
  );
}
